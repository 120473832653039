export const useFormRules = () => {
  const forbiden_table_names = ["user", "session", "verificationtoken", "account", "schema", "param", "folder", "session", "id", "table"]

	return {
		ruleRequired: (v: any) => !!v || "Required",
    ruleRequiredOptions: (v: Array<string>) => v && v.length>0 || "Required",
    ruleRequiredOptionsObject: (v: Array<object>) => v && v.length>0 || "Required",
    ruleLandN: (value: any) => {
      const pattern = /^[a-zA-Z0-9]+$/;
      return pattern.test(value) || "Only letters and numbers";
    },
    ruleURL: (value: any) =>{
      const pattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}/gm
      return pattern.test(value) || "URL is not valid. It must look like this: https://www.example.com/"
    },
		ruleEmail: (value: any) => {
			const pattern =
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return pattern.test(value) || "Enter a valid email";
		},
		rulePassLen: (v: string) => (!!v && v.length >= 6) || "Password must be 6 chars or more",
    ruleNoReservedTables: (v: string) => (!forbiden_table_names.includes(v.toLowerCase())) || "This name is reserved by the system",
	};
};